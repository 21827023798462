const cache = new Map();
const CACHE_DURATION = 5000; // 5 seconds expiration

// Save response or in-progress request to cache
const store = (key, data, timeout = CACHE_DURATION) => {
    cache.set(key, { data });

    // Automatically delete after 5 seconds
    setTimeout(() => {
        cache.delete(key);
        console.log(`Cache: expired (${key})`);
    }, timeout);
};

// Retrieve cached response or in-progress request
const fetch = (key) => {
    return cache.get(key)?.data || null;
};

// Remove an entry from cache (e.g., on request failure)
const remove = (key) => {
    cache.delete(key);
    console.log('Cache: deleted entry');
};

// Export cache functions
const CacheService = {
    fetch,
    store,
    remove
};

export default CacheService;
