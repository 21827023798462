import API from './api';
import CacheService from './cacheService';

const CachedApi = {
    get: (url, params) => {
        // Check if there's a cached response or an in-progress request
        const key = JSON.stringify({ url, params })
        const cachedResponse = CacheService.fetch(key);

        if (cachedResponse) {
            console.log(`Cache: returning cached response for ${url}`);
            return Promise.resolve(cachedResponse);
        }

        // Make a new API request
        const requestPromise = API.get(url, params)
            .then((response) => {
                CacheService.store(key, response);
                return response;
            })
            .catch((error) => {
                CacheService.remove(key);
                return Promise.reject(error);
            });

        // Cache in-progress request to prevent duplicates
        CacheService.store(key, requestPromise);

        return requestPromise;
    }
};

export default CachedApi;
