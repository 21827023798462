import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store/index';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://bde57bfcb6448ee224158cd417cdf4b3@o1232071.ingest.us.sentry.io/4507466636525568",
  integrations: [],
  beforeSend(event) {
    try {
      const getSafeItem = (key) => localStorage.getItem(key) || null;

      event.user = {
        email: getSafeItem("user_email"),
        first_name: getSafeItem("user_first_name"),
        last_name: getSafeItem("user_last_name"),
      };

      event.extra = {
        loginEmail: getSafeItem("loginEmail"),
        awsBucketName: getSafeItem("awsBucketName"),
        dateFormat: getSafeItem("date_format"),
        subscriptionPlan: getSafeItem("subscription_plan_name"),
        language: getSafeItem("language"),
        isAdmin: getSafeItem("isAdmin"),
        usaUnits: getSafeItem("usa_units"),
      };

      return event;
    } catch (error) {
      console.error("Sentry beforeSend error:", error);
      return event; // Ensure nothing breaks if something goes wrong
    }
  },
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>, document.getElementById('root'));
