import enLabels from '../pages/languages/enLabels'
import esLabels from '../pages/languages/esLabels'

export function getComponentLabels(component){
    const labels = getabels(localStorage.getItem("language"))
    return labels[component]
}

export function getabels(language){
    switch(language) {
      case 'spanish':
        return esLabels;
      case 'english':
        return enLabels;
      default:
        return esLabels;
    }
}