import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import React from "react";

export default function WarningDialog({open,
                                       onClose,
                                       title,
                                       contentText,
                                       onCancelClick,
                                       onContinueClick,
                                       cancelButton=false
                                               })
        {
        return (
            <React.Fragment>
                <Dialog
                  open={open}
                  onClose={onClose}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description">
                  <DialogTitle id="alert-dialog-slide-title">
                      {title}
                  </DialogTitle>
                  {contentText &&
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      {contentText}
                    </DialogContentText>
                  </DialogContent>}
                  <DialogActions>
                      {
                          cancelButton &&
                            <Button onClick={onCancelClick} color="primary">
                              Cancelar
                            </Button>
                      }
                    <Button onClick={onContinueClick} color="primary">
                      Continuar
                    </Button>
                  </DialogActions>
                </Dialog>
            </React.Fragment>
)
}
