import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components";
import {connect} from "react-redux";
import store from '../redux/store'
import { withStyles } from '@material-ui/core/styles';
import {
    Grid,
    Hidden,
    Menu,
    MenuItem,
    AppBar as MuiAppBar,
    IconButton as MuiIconButton,
    Toolbar, Tooltip, Badge
} from "@material-ui/core";
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { Menu as MenuIcon } from "@material-ui/icons";
import {
  User
} from "react-feather";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { AuthService } from "../services/AuthService";
import { useHistory } from "react-router-dom";
import Breadcrumb from "./Breadcrumb"
import {setIntroJs} from "../redux/actions/introJsActions";
import { Permissions, hasPermission } from "./permissions/permissions";
import { NotificationsService } from "../services/NotificationsService"
import { getComponentLabels } from "../utils/languages";

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Greeting = styled.p`
  font-size: 1.1em; 
  margin-right: 10px; 
`;

const StyledMenu = withStyles(theme => ({
  paper: {
    border: '1px solid #d3d4d5',
    background: theme.sidebar.background,
    color: theme.sidebar.color
  },
}))((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export function UserMenu({ labels }) {
  const [anchorMenu, setAnchorMenu] = useState(null);
  const open = Boolean(anchorMenu);
  const history = useHistory();

  return (
    <React.Fragment>
      <IconButton
        aria-owns={open ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={(event) => setAnchorMenu(event.currentTarget)}
        color="inherit"
      >
        <User/>
      </IconButton>
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={open}
        onClose={() => setAnchorMenu(null)}
      >
        {hasPermission(Permissions['ORGANIZATION']) && 
        <>
        <MenuItem
          onClick={() => history.push('/organizations')}
        >
          {labels["my_organization"]}
        </MenuItem>
        <MenuItem
          onClick={() => history.push('/employees')}
        >
          {labels["my_employees"]}
        </MenuItem></>}
        <MenuItem
          onClick={() => history.push('/')}
        >
          {labels["my_subscription"]}
        </MenuItem>
        <MenuItem
          onClick={() => history.push('/user/change-password')}
        >
          {labels["password_change"]}
        </MenuItem>
        <MenuItem
          onClick={() => AuthService.logOut(history)}
        >
          {labels["log_out"]}
        </MenuItem>
      </StyledMenu>
    </React.Fragment>
  );

}

export function ReactivateIntroJsButton({ labels }) {
    const handleOnClick = event => store.dispatch(setIntroJs(true))

    return(
        <Tooltip title={labels["help"]}>
            <IconButton
                color="inherit"
                aria-label="help"
                onClick={handleOnClick}
            >
                <HelpOutlineIcon/>
            </IconButton>
        </Tooltip>
    )
}

export function NotificationsButton() {
  const history = useHistory();
  const [notificationsQuantity, setnotificationsQuantity] = useState(0)

  useEffect(()=>{
      NotificationsService.getNotificationsCount().then((response) => {
        setnotificationsQuantity(response.data.unread_count)
      })
  }, [])

  return(
    <Tooltip title={'Notificaciones'}>
      <IconButton
        color="inherit"
        aria-label="help"
        onClick={() => history.push('/notifications')}
      >
        <Badge
          variant={notificationsQuantity > 0 ? 'dot' : 'standard'}
          color="secondary"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          overlap="circle"
          style={{ transform: 'scale(1)' }}
        >
          <NotificationsNoneIcon />
        </Badge>
      </IconButton>
    </Tooltip>
  )
}

export function userGreeting(labels) {
  return <p>{labels["greeting"]}, {localStorage.getItem('user_first_name') !== "null" ? localStorage.getItem('user_first_name') :  localStorage.getItem('user_email')}!</p>;
};

const Header = ({ onDrawerToggle }) =>  {
  const history = useHistory()
  const [labels, setLabels] = useState("")

  useEffect(
    () => {
      setLabels(getComponentLabels("Header"))
    },
    []
  )
  
return(
  <React.Fragment>
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center">
          <Hidden mdUp>
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          {window.innerWidth > 500 &&
            <Grid item>
            <Breadcrumb history={history}/>
          </Grid>}
          <Grid item xs />
          <Grid item>
            <Greeting>{userGreeting(labels)}</Greeting>
          </Grid>
          <Grid item>
            <NotificationsButton/>
          </Grid>
          <Grid item>
            <ReactivateIntroJsButton labels={labels}/>
          </Grid>
          <Grid item>
            <UserMenu labels={labels}/>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  </React.Fragment>
);}

export default connect()(withTheme(Header));
