import API from './api';
import CachedApi from './cachedApi';
import {buildUrlFor} from './urls';
import {enqueueSnackbar} from "../redux/actions/snackbarActions"
import store from "../redux/store";
import {successMessage, errorMessage} from "./alertMessages";
import snackbarMessages from "./snackbarMessages"

export const ItemsService = {
    get: function (model, paginationParams, api = API) {
      const url = buildUrlFor(model)
      return CachedApi.get(url, {params: paginationParams})
        .then(function (response) {
          // Returning a deep copy of the data to avoid modifying the cached data
          return {data: JSON.parse(JSON.stringify(response.data))};
        })
        .catch(function (error) {
          store.dispatch(enqueueSnackbar(errorMessage(snackbarMessages.error[model].get)))
          return {error};
        })
    },
    getById: function (model,id) {
      const url = buildUrlFor(model)
      return API.get(url + id, id)
        .then(function (response) {
          return {data: response.data};
        })
        .catch(function (error) {
          store.dispatch(enqueueSnackbar({ open: true, variant: 'error', message: 'Se produjo un error'}))
          return {error};
        })
    },
    post: function(model, params){
      const url = buildUrlFor(model)
      return API.post(url, params)
          .then((response) => {
            store.dispatch(enqueueSnackbar(successMessage(snackbarMessages.success[model].post)))
            return response
          })
          .catch((error) => {
            store.dispatch(enqueueSnackbar(errorMessage(snackbarMessages.error[model].post)))
            return error
          })
    },
    update: function(model, params){
      const url = buildUrlFor(model)
      return API.patch(url + params.id, params)
          .then((response) => {
            store.dispatch(enqueueSnackbar(successMessage(snackbarMessages.success[model].update)))
            return response
          })
          .catch((error) => {
            store.dispatch(enqueueSnackbar(errorMessage(snackbarMessages.error[model].update)))
            return error
          })
    },
    destroy: function(model, params){
      const url = buildUrlFor(model)
      return API.delete(url + params.id)
        .then(function (response) {
          store.dispatch(enqueueSnackbar(successMessage(snackbarMessages.success[model].delete)))
          return {data: response.data};
        })
        .catch(function (error) {
          const message = snackbarMessages.error[model][error.response.data[0]]
            ?? snackbarMessages.error[model].delete;
          store.dispatch(enqueueSnackbar(errorMessage(message)))
          return {error};
        })

    }
}
