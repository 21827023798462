import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import AuthLayout from "../layouts/Auth";
import { Button as MuiButton, Typography} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import WarningDialog from "./DataTable/warningDialog";
import * as Sentry from '@sentry/react';

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  text-align: center;
  background-color: ${props => props.theme.palette.primary.main};
  height: 100%;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`;

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error);
    console.log(errorInfo);

    Sentry.captureMessage("ERROR:" + error + " - " + JSON.stringify(errorInfo));
  }

  render() {
    if (this.state.hasError) {
      return(
        <WarningDialog
                open={true}
                onClose={() => window.location.reload()}
                title={"Ocurrió un error"}
                contentText={"Por favor inténtelo de nuevo, haga click en continuar para recargar la pagina."}
                cancelButton={false}
                onContinueClick={() => window.location.reload()}
            />
    );
  }
    return this.props.children;
  }
} 